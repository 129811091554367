import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AurmSkuQrDisplay from '../components/AurmSkrQrDisplay';
function AppRouter() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<AurmSkuQrDisplay/>} />
      <Route path="/skus" element={<AurmSkuQrDisplay/>} />
    </Routes>
  </Router>
  );
}

export default AppRouter;