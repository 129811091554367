import React, { useEffect, useState } from 'react';
import '../css/aurm_sku_qr_display.css'; // Import the CSS file

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_API_KEY;
const DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];
const SCOPES = "https://www.googleapis.com/auth/spreadsheets.readonly";
function AurmSkuQrDisplay() {
    const [data, setData] = useState([]);
    const [params, setParams] = useState([]);
    const [isSheetData, setIsSheetData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const initializeGoogleApis = async () => {
            try {
                await Promise.all([
                    loadScript('https://apis.google.com/js/api.js'),
                    loadScript('https://accounts.google.com/gsi/client')
                ]);

                if (window.gapi && window.google) {
                    await new Promise((resolve) => window.gapi.load('client', resolve));
                    await window.gapi.client.init({
                        apiKey: API_KEY,
                        discoveryDocs: DISCOVERY_DOCS,
                    });

                    const tokenClient = window.google.accounts.oauth2.initTokenClient({
                        client_id: CLIENT_ID,
                        scope: SCOPES,
                        callback: (response) => {
                            if (response && response.access_token) {
                                window.gapi.client.setToken({ access_token: response.access_token });
                                setIsAuthorized(true);
                            } else {
                                setError('Failed to get access token');
                            }
                        }
                    });

                    // Only request the token if not already authorized
                    if (!isAuthorized) {
                        tokenClient.requestAccessToken();
                    }
                } else {
                    throw new Error('Google APIs not loaded');
                }
            } catch (error) {
                console.error('Failed to initialize Google APIs:', error);
                setError('Failed to initialize Google APIs. Please try again.');
            }
        };

        // Initialize Google APIs and handle data fetch based on authorization
        const initializeAndFetchData = async () => {
            try {
                await initializeGoogleApis();
                if (isAuthorized) {
                    const urlParams = new URLSearchParams(window.location.search);
                    if (urlParams.has('SHEET_ID')) {
                        setIsSheetData(true);
                        const rowNumber = urlParams.get('ROW_NUM') || 5;
                        const accessToken = await getAccessToken();
                        const sheetData = await loadSheet(accessToken, rowNumber);
                        displaySkuSheetData(sheetData);
                    } else {
                        setIsSheetData(false);
                        const qrHeaders = ["Parameter", "Value"];
                        displaySkuQrData(urlParams, qrHeaders);
                    }
                }
            } catch (error) {
                console.error('Error:', error);
                setError('Failed to fetch data. Please try again.');
            } finally {
                setIsLoading(false);
            }
        };

        initializeAndFetchData();
    }, [isAuthorized]); // Ensure `isAuthorized` is in the dependency array

    const loadScript = (url) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = url;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Script load error for ${url}`));
            document.body.appendChild(script);
        });
    };

    const getAccessToken = async () => {
        const jwtToken = await generateJWT();
        const response = await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
                assertion: jwtToken
            })
        });

        const data = await response.json();
        return data.access_token;
    };

    const generateJWT = async () => {
        const serviceAccountCredentials = {
            type: process.env.REACT_APP_TYPE,
            project_id: process.env.REACT_APP_PROJECT_ID,
            private_key_id: process.env.REACT_APP_PRIVATE_KEY_ID,
            private_key: process.env.REACT_APP_PRIVATE_KEY,
            client_email: process.env.REACT_APP_CLIENT_EMAIL,
            client_id: process.env.REACT_APP_CLIENT_ID,
            auth_uri: process.env.REACT_APP_AUTH_URI,
            token_uri: process.env.REACT_APP_TOKEN_URI,
            auth_provider_x509_cert_url: process.env.REACT_APP_AUTH_PROVIDER_X509_CERT_URL,
            client_x509_cert_url: process.env.REACT_APP_CLIENT_X509_CERT_URL,
            universe_domain: process.env.REACT_APP_UNIVERSE_DOMAIN
        };

        if (!serviceAccountCredentials.private_key) {
            throw new Error("Private key is not defined");
        }

        const header = {
            alg: "RS256",
            typ: "JWT"
        };

        const iat = Math.floor(Date.now() / 1000);
        const exp = iat + 3600;

        const payload = {
            iss: serviceAccountCredentials.client_email,
            scope: SCOPES,
            aud: serviceAccountCredentials.token_uri,
            exp: exp,
            iat: iat
        };

        const unsignedToken = `${base64urlEncode(JSON.stringify(header))}.${base64urlEncode(JSON.stringify(payload))}`;
        const signature = await signWithPrivateKey(unsignedToken, serviceAccountCredentials.private_key);

        return `${unsignedToken}.${signature}`;
    };

    const base64urlEncode = (str) => {
        return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    };

    const signWithPrivateKey = async (unsignedToken, privateKey) => {
        const key = await crypto.subtle.importKey(
            "pkcs8",
            str2ab(atob(privateKey.replace(/-----\w+ PRIVATE KEY-----/g, ''))),
            {
                name: "RSASSA-PKCS1-v1_5",
                hash: "SHA-256"
            },
            false,
            ["sign"]
        );

        const signature = await crypto.subtle.sign("RSASSA-PKCS1-v1_5", key, new TextEncoder().encode(unsignedToken));
        return base64urlEncode(String.fromCharCode(...new Uint8Array(signature)));
    };

    const str2ab = (str) => {
        const buffer = new ArrayBuffer(str.length);
        const bufferView = new Uint8Array(buffer);
        for (let i = 0; i < str.length; i++) {
            bufferView[i] = str.charCodeAt(i);
        }
        return buffer;
    };

    const loadSheet = async (accessToken, rowNumber) => {
        const SPREADSHEET_ID = '1IP4ZTD97PUjSu4BsnCTBnWiIVa-RpqeJnXe9e4EBJo0'; 
        const sheetName = 'Database';
        const range = `${sheetName}!A${rowNumber}:Z${rowNumber}`;
        const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${range}`, {
            headers: { Authorization: `Bearer ${accessToken}` }
        });
        const data = await response.json();
        return data.values || [];
    };

    const displaySkuQrData = (parameters, qrHeaders) => {
        const paramArray = Array.from(parameters.entries());
        setParams(paramArray);
    };

    const displaySkuSheetData = (data) => {
        setData(data || []);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="container">
            <div id="sku_params_heading">AURM SKU GET</div>
            <table className="sku_paramsTable">
                <thead>
                    <tr>
                        <th className="sku_params_tbl_headers">Parameter</th>
                        <th className="sku_params_tbl_headers">Value</th>
                    </tr>
                </thead>
                <tbody>
                {isSheetData
    ? (data.length > 0
        ? data.map((rowData, rowIndex) => (
            rowData.length > 0 ? (
                <React.Fragment key={rowIndex}>
                    {rowData.slice(0, 9).map((cell, cellIndex) => (
                        <tr key={cellIndex}>
                            <td>{[
                                "Site", 
                                "Asset Tag No", 
                                "Item", 
                                "SKU", 
                                "Supplier/Manuf", 
                                "Receiving Stn", 
                                "GRN No", 
                                "GRN Date", 
                                "Issued by"
                            ][cellIndex]}</td>
                            <td>{cell}</td>
                        </tr>
                    ))}
                </React.Fragment>
            ) : (
                <tr key={rowIndex}>
                    <td colSpan="2">No data found for this row</td>
                </tr>
            )
        ))
        : <tr><td colSpan="2">No data found</td></tr>
    )
    : params.map(([key, value], index) => (
        <tr key={index}>
            <td>{key}</td>
            <td>{value}</td>
        </tr>
    ))
}

                </tbody>
            </table>
        </div>
    );
}

export default AurmSkuQrDisplay;
